/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
body {
  padding: 0;
  margin: 0;
  font-family: "Maven+Pro", sans-serif;
  font-size: 14px;
}

a {
  display: block;
  transition: 0.5s;
  text-decoration: none;
  color: #333333;
}
a:hover, a:focus {
  text-decoration: none;
  color: #ff4800;
}

button {
  outline: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #333333;
  font-family: "Comfortaa", sans-serif;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 14px;
  color: #57647c;
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

.ptb-110 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

img {
  max-width: 100%;
}

.bg-f2f6f9 {
  background-color: #f2f6f9;
}

.bg-fafafa {
  background-color: #fafafa;
}

/*section-title*/
.section-title {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: -6px;
}
.section-title span {
  display: block;
  color: #ff4800;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  font-family: "Comfortaa", sans-serif;
}
.section-title h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
}
.section-title p {
  max-width: 520px;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
.section-title.with-underline-text {
  max-width: 700px;
}
.section-title.with-underline-text h2 b {
  display: inline-block;
  text-decoration-line: underline;
  color: #437FEC;
  font-weight: bold;
}
.section-title.with-underline-text p {
  max-width: 100%;
}

/*btn-primary*/
.btn {
  border: none;
  line-height: initial;
  border-radius: 30px;
  transition: 0.5s;
  padding: 10px 32px 12px;
  position: relative;
  z-index: 1;
  opacity: 1 !important;
  font-size: 16px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
}

.btn-primary {
  color: #ffffff;
  background-color: #ff4800;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #ff4800;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #4ac728 !important;
  color: #ffffff !important;
  box-shadow: unset !important;
}

/*learn-more-btn*/
.learn-more-btn {
  display: inline-block;
  position: relative;
  color: #ff4800;
  letter-spacing: 0.5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}
.learn-more-btn i {
  margin-left: 2px;
  position: relative;
  top: -0.6px;
}
.learn-more-btn i::before {
  font-size: 11px;
}
.learn-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  background: #ff4800;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.learn-more-btn:hover {
  color: #ff4800;
}
.learn-more-btn:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

/*form-control*/
.form-control {
  height: 45px;
  border: 1px solid #eeeeee;
  background-color: transparent;
  border-radius: 0;
  transition: 0.5s;
  color: #333333;
  line-height: initial;
  padding-left: 13px;
  font-size: 14px;
}
.form-control:hover, .form-control:focus {
  box-shadow: unset;
}
.form-control::placeholder {
  color: #999999;
}
.form-control:focus {
  box-shadow: unset;
  border-color: #ff4800;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  left: 0;
  width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  transition: 0.9s;
}
.preloader .loader {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 45%;
  transform: translateY(-45%);
  transition: 0.5s;
}
.preloader .loader .loader-outter {
  position: absolute;
  border: 4px solid #ffffff;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader .loader .loader-inner {
  position: absolute;
  border: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader::before, .preloader::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60%;
  z-index: -1;
  background: #ff4800;
  transition: 0.9s;
}
.preloader::after {
  top: auto;
  bottom: 0;
}
.preloader.preloader-deactivate {
  visibility: hidden;
}
.preloader.preloader-deactivate::after, .preloader.preloader-deactivate::before {
  height: 0;
}
.preloader.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

@keyframes loader-outter {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-brand img.optional-logo {
  display: none;
}

.kirin-nav {
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
}
.kirin-nav .navbar {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.kirin-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.kirin-nav .navbar .navbar-nav {
  font-family: "Comfortaa", sans-serif;
  margin-left: auto;
}
.kirin-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-toggle::after {
  display: none !important;
}
.kirin-nav .navbar .navbar-nav .nav-item a {
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 13px;
  margin-right: 13px;
}
.kirin-nav .navbar .navbar-nav .nav-item a:hover, .kirin-nav .navbar .navbar-nav .nav-item a:focus, .kirin-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item a i {
  font-size: 10px;
}
.kirin-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item:hover a, .kirin-nav .navbar .navbar-nav .nav-item:focus a, .kirin-nav .navbar .navbar-nav .nav-item.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 55px;
  left: 0;
  width: 250px;
  z-index: 99;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  color: #333333;
  font-size: 16px;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #ff4800;
}
.kirin-nav .navbar .others-options {
  margin-left: 20px;
}
.kirin-nav .navbar .others-options .cart-btn {
  display: inline-block;
  position: relative;
  top: 3px;
  color: #ffffff;
}
.kirin-nav .navbar .others-options .cart-btn:hover {
  color: #ff4800;
}
.kirin-nav .navbar .others-options .btn {
  margin-left: 15px;
}
.kirin-nav .navbar .others-options .btn-primary {
  background-color: #ffffff;
  color: #ff4800;
}
.kirin-nav .navbar .others-options .btn-primary:hover {
  color: #ffffff !important;
  background-color: #ff4800 !important;
}
.kirin-nav .navbar .others-options .option-item {
  color: #333333;
  display: inline-block;
  position: relative;
  line-height: initial;
  margin-left: 15px;
  top: 4px;
}
.kirin-nav .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  transition: 0.5s;
  color: #ffffff;
}
.kirin-nav .navbar .others-options .option-item .search-btn::before {
  font-size: 17px;
}
.kirin-nav .navbar .others-options .option-item .search-btn:hover {
  color: #ff4800;
}
.kirin-nav .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  font-size: 17px;
  transition: 0.5s;
  width: 17px;
  color: #ffffff;
}
.kirin-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}
.kirin-nav .navbar .others-options .option-item .close-btn:hover {
  color: #ff4800;
}

.navbar-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  transition: 0.5s;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.5s;
}
.navbar-area.is-sticky .kirin-nav {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item:hover a, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item:focus a, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .cart-btn {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .cart-btn:hover {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .btn-primary {
  background-color: #ff4800;
  color: #ffffff;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .btn-primary:hover {
  color: #ffffff !important;
  background-color: #4ac728 !important;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .option-item {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .option-item .search-btn {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .option-item .search-btn:hover {
  color: #ff4800;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .option-item .close-btn {
  color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar .others-options .option-item .close-btn:hover {
  color: #ff4800;
}
.navbar-area.is-sticky .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area.is-sticky .navbar-brand img.main-logo {
  display: none;
}
.navbar-area.is-sticky .navbar-brand img.optional-logo {
  display: block;
}
.navbar-area.navbar-style-two .kirin-nav {
  padding-left: 0;
  padding-right: 0;
}
.navbar-area.p-relative {
  position: relative;
}
.navbar-area.p-relative .kirin-nav .navbar .others-options .btn-primary {
  background-color: #f4f4f4;
  color: #ff4800;
}
.navbar-area.p-relative .kirin-nav .navbar .others-options .btn-primary:hover {
  color: #ffffff !important;
  background-color: #ff4800 !important;
}

.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 40px;
  line-height: initial;
  border: 1px solid #eeeeee;
  padding-left: 10px;
  color: #333333;
  outline: 0;
  transition: 0.5s;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #ff4800;
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  background: transparent;
  border: none;
  width: 40px;
  outline: 0;
  color: #57647c;
  transition: 0.5s;
}
.search-overlay.search-popup .search-form .search-button:hover, .search-overlay.search-popup .search-form .search-button:focus {
  color: #ff4800;
}

.navbar-style-three .kirin-nav {
  padding-left: 0;
  padding-right: 0;
}
.navbar-style-three .kirin-nav .navbar-brand img {
  display: inline-block !important;
}
.navbar-style-three .kirin-nav .navbar-brand img.optional-logo {
  display: none !important;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .others-options .cart-btn {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .others-options .cart-btn:hover {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .others-options .option-item {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .others-options .option-item .search-btn {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .others-options .option-item .search-btn:hover {
  color: #ff4800;
}
.navbar-style-three .kirin-nav .navbar .others-options .option-item .close-btn {
  color: #333333;
}
.navbar-style-three .kirin-nav .navbar .others-options .option-item .close-btn:hover {
  color: #ff4800;
}

.navbar-style-four .kirin-nav .navbar-brand img {
  display: inline-block !important;
}
.navbar-style-four .kirin-nav .navbar-brand img.optional-logo {
  display: none !important;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
  text-transform: capitalize;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .others-options .cart-btn {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .others-options .cart-btn:hover {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .others-options .option-item {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .others-options .option-item .search-btn {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .others-options .option-item .search-btn:hover {
  color: #ff4800;
}
.navbar-style-four .kirin-nav .navbar .others-options .option-item .close-btn {
  color: #333333;
}
.navbar-style-four .kirin-nav .navbar .others-options .option-item .close-btn:hover {
  color: #ff4800;
}

@media only screen and (min-width: 992px) {
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    position: absolute;
    top: 80px;
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 220px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
    left: 220px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
}
.navbar-area.is-sticky .kirin-nav .navbar-light .navbar-toggler {
  color: #333333;
  border-color: #333333;
}
.navbar-area.is-sticky .kirin-nav .navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.kirin-nav .navbar-light .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff;
  border-radius: 0;
}
.kirin-nav .navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-style-three .kirin-nav .navbar-light .navbar-toggler {
  color: #333333;
  border-color: #333333;
}
.navbar-style-three .kirin-nav .navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-style-four .kirin-nav .navbar-light .navbar-toggler {
  color: #333333;
  border-color: #333333;
}
.navbar-style-four .kirin-nav .navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media only screen and (max-width: 767px) {
  .navbar-area.is-sticky .kirin-nav {
    border-bottom: none;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-area.is-sticky .kirin-nav .navbar-light #navbarSupportedContent {
    border: 1px solid #eeeeee;
  }
  .kirin-nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 0;
    padding-left: 0;
  }
  .kirin-nav .navbar .navbar-nav {
    padding: 12px 20px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item a {
    color: #677294;
    margin-left: 0;
    margin-right: 0;
  }
  .kirin-nav .navbar .navbar-nav .nav-item a.active {
    color: #ff4800;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    top: 0;
    left: 0 !important;
    right: 0 !important;
    position: relative;
    width: 100%;
    margin-top: 15px;
  }
  .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    margin-top: 0 !important;
  }
  .kirin-nav .navbar-light #navbarSupportedContent {
    background-color: #ffffff;
    margin-top: 15px;
  }
  .others-options {
    display: none;
  }
}
/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 950px;
  position: relative;
  background-image: url(assets/img/main-banner.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.main-banner .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.main-banner-content {
  margin-top: -160px;
  max-width: 720px;
}
.main-banner-content h1 {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 70px;
  font-weight: 700;
}
.main-banner-content p {
  font-size: 15px;
  color: #ffffff;
  max-width: 600px;
  margin-top: 25px;
  margin-bottom: 0;
}
.main-banner-content .btn-box {
  margin-top: 30px;
}
.main-banner-content .btn-box .optional-btn {
  display: inline-block;
  color: #ffffff;
  position: relative;
  margin-left: 20px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.main-banner-content .btn-box .optional-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: 0.5s;
}
.main-banner-content .btn-box .optional-btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ff4800;
  transition: 0.5s;
}
.main-banner-content .btn-box .optional-btn:hover {
  color: #ffffff;
}
.main-banner-content .btn-box .optional-btn:hover::before {
  width: 0;
}
.main-banner-content .btn-box .optional-btn:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}

.banner-section {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-image: url(assets/img/banner-bg-shape.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 240px;
  padding-bottom: 120px;
}

.banner-content h1 {
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 67px;
  font-weight: 700;
}
.banner-content p {
  font-size: 15px;
  max-width: 600px;
  margin-top: 25px;
  margin-bottom: 0;
}
.banner-content .btn-box {
  margin-top: 30px;
}
.banner-content .btn-box .optional-btn {
  display: inline-block;
  color: #333333;
  position: relative;
  margin-left: 20px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.banner-content .btn-box .optional-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #333333;
  transition: 0.5s;
}
.banner-content .btn-box .optional-btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ff4800;
  transition: 0.5s;
}
.banner-content .btn-box .optional-btn:hover {
  color: #ff4800;
}
.banner-content .btn-box .optional-btn:hover::before {
  width: 0;
}
.banner-content .btn-box .optional-btn:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}

.banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  top: -75px;
}
.banner-image img {
  position: absolute;
}
.banner-image img:nth-child(1) {
  left: 115px;
  bottom: 70px;
  z-index: 4;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.banner-image img:nth-child(2) {
  left: 87px;
  bottom: 45px;
  z-index: 3;
}
.banner-image img:nth-child(3) {
  right: -43px;
  bottom: -35px;
}
.banner-image img:nth-child(4) {
  left: -40px;
  bottom: -75px;
  z-index: 3;
}
.banner-image img:nth-child(5) {
  left: 40px;
  bottom: -55px;
  z-index: 2;
}
.banner-image img:nth-child(6) {
  left: -45px;
  top: 185px;
  z-index: 1;
}
.banner-image img:nth-child(7) {
  right: 160px;
  top: 0;
}
.banner-image img:nth-child(8) {
  left: 30px;
  bottom: -63px;
}
.banner-image img.main-pic {
  display: none;
}

.hero-banner {
  position: relative;
  z-index: 1;
  background-image: url(assets/img/banner-bg-shape.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 100px;
}
.hero-banner .container-fluid {
  padding-left: 70px;
  padding-right: 70px;
}

.hero-banner-content h1 {
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 67px;
  font-weight: 700;
}
.hero-banner-content p {
  font-size: 15px;
  max-width: 600px;
  margin-top: 25px;
  margin-bottom: 0;
}
.hero-banner-content .btn-box {
  margin-top: 30px;
}
.hero-banner-content .btn-box .optional-btn {
  display: inline-block;
  color: #333333;
  position: relative;
  margin-left: 20px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.hero-banner-content .btn-box .optional-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #333333;
  transition: 0.5s;
}
.hero-banner-content .btn-box .optional-btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ff4800;
  transition: 0.5s;
}
.hero-banner-content .btn-box .optional-btn:hover {
  color: #ff4800;
}
.hero-banner-content .btn-box .optional-btn:hover::before {
  width: 0;
}
.hero-banner-content .btn-box .optional-btn:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}

.hero-banner-image {
  text-align: center;
}

/*================================================
Featured Services Area CSS
=================================================*/
.featured-services-area {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: transparent;
}

.single-featured-services-box {
  position: relative;
  z-index: 1;
  transition: 0.5s;
  padding: 20px 25px;
}
.single-featured-services-box .icon {
  margin-bottom: 15px;
  color: #ff4800;
  transition: 0.5s;
  line-height: 1;
}
.single-featured-services-box .icon i {
  font-size: 45px;
}
.single-featured-services-box .icon i::before {
  font-size: 45px;
}
.single-featured-services-box h3 {
  margin-bottom: 0;
  color: #ffffff;
  transition: 0.5s;
  font-size: 23px;
  font-weight: 700;
}
.single-featured-services-box h3 a {
  color: #ffffff;
}
.single-featured-services-box p {
  color: #dddddd;
  transition: 0.5s;
  margin-top: 12px;
  margin-bottom: 0;
}
.single-featured-services-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ff4800;
  width: 2px;
  height: 100%;
  transition: 0.5s;
  z-index: -1;
}
.single-featured-services-box:hover .icon, .single-featured-services-box.active .icon {
  color: #ffffff;
}
.single-featured-services-box:hover h3, .single-featured-services-box.active h3 {
  color: #ffffff;
}
.single-featured-services-box:hover h3 a, .single-featured-services-box.active h3 a {
  color: #ffffff;
}
.single-featured-services-box:hover p, .single-featured-services-box.active p {
  color: #f0f0f0;
}
.single-featured-services-box:hover::before, .single-featured-services-box.active::before {
  width: 100%;
}

/*================================================
Featured Solutions Area CSS
=================================================*/
.featured-solutions-area {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

.single-featured-solutions-box {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  padding: 35px;
  border-radius: 5px;
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
}
.single-featured-solutions-box .icon {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}
.single-featured-solutions-box .icon img {
  transition: 0.5s;
}
.single-featured-solutions-box .icon::before {
  height: 50px;
  width: 50px;
  background: linear-gradient(to right, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  content: "";
  border-radius: 50%;
  transition: 0.5s;
}
.single-featured-solutions-box h3 {
  margin-bottom: 0;
  transition: 0.5s;
  font-size: 23px;
  font-weight: 700;
}
.single-featured-solutions-box p {
  margin-top: 13px;
  margin-bottom: 0;
}
.single-featured-solutions-box .learn-more-btn {
  margin-top: 12px;
  font-size: 16px;
}
.single-featured-solutions-box .learn-more-btn::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.single-featured-solutions-box .learn-more-btn:hover::before {
  width: 0;
}
.single-featured-solutions-box:hover .icon img {
  transform: rotateY(-180deg);
}
.single-featured-solutions-box:hover .icon::before {
  transform: translateY(-50%) rotate(180deg);
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.about-image {
  position: relative;
  padding-bottom: 100px;
}
.about-image img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 10px 0 0;
}

.about-content {
  padding-left: 25px;
}
.about-content h2 {
  margin-bottom: 18px;
  line-height: 1.3;
  margin-top: -10px;
  font-size: 40px;
  font-weight: 700;
}
.about-content p {
  margin-bottom: 13px;
}
.about-content .btn {
  margin-top: 10px;
}

.about-inner-area {
  margin-top: 60px;
}

.about-text h3 {
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 700;
}
.about-text p {
  margin-top: 13px;
  margin-bottom: 0;
}

.about-img {
  text-align: center;
}

.about-content .features-list {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: -10px;
  margin-left: -10px;
}
.about-content .features-list li {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 13px;
}
.about-content .features-list li span {
  display: block;
  position: relative;
  color: #333333;
  padding-left: 28px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.about-content .features-list li span i {
  width: 20px;
  display: inline-block;
  height: 20px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 4px;
  color: #ffffff;
  line-height: 16px;
  background-color: #ff4800;
  text-align: center;
  transition: 0.5s;
}
.about-content .features-list li span i::before {
  font-size: 10px;
}
.about-content .features-list li span:hover i {
  background-color: #4ac728;
}

.shape-img1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
  opacity: 0.4;
}

.shape-img2 {
  position: absolute;
  left: 5%;
  top: 5%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img7 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.2;
  animation: rotate3d 4s linear infinite;
}

.shape-img3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.1;
  animation: rotate3d 4s linear infinite;
}

.shape-img4 {
  position: absolute;
  right: 40%;
  bottom: 8%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.shape-img5 {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  opacity: 0.7;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img6 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: -1;
  opacity: 0.4;
  animation: moveLeftBounce 5s linear infinite;
}

.dot-shape1 {
  position: absolute;
  top: 5%;
  left: 50%;
  z-index: -1;
  animation: animationFramesTwo 40s infinite linear;
  opacity: 0.5;
}

.dot-shape2 {
  position: absolute;
  left: 5%;
  top: 45%;
  z-index: -1;
  animation: animationFramesTwo 30s infinite linear;
  opacity: 0.5;
}

.dot-shape4 {
  position: absolute;
  right: 15%;
  top: 50%;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}

.dot-shape5 {
  position: absolute;
  left: 30%;
  top: 50%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.dot-shape6 {
  position: absolute;
  right: 45%;
  bottom: 5%;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}

@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
/*================================================
Services Area CSS
=================================================*/
.services-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
}
.services-area .pagination-area {
  margin-bottom: 30px;
}

.single-services-box {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px 25px;
  transition: 0.5s;
  position: relative;
  text-align: center;
}
.single-services-box .icon {
  margin-bottom: 20px;
  background-color: #ffe4d9;
  border-radius: 5px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  transition: 0.5s;
  text-align: center;
  color: #ff4800;
  margin-left: auto;
  margin-right: auto;
}
.single-services-box .icon i::before {
  font-size: 35px;
}
.single-services-box h3 {
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 700;
}
.single-services-box p {
  margin-top: 12px;
  margin-bottom: 0;
}
.single-services-box::before {
  content: "";
  height: 2px;
  width: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ff4800;
  transition: 0.5s;
}
.single-services-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
}
.single-services-box:hover .icon {
  background-color: #ff4800;
  color: #ffffff;
}
.single-services-box:hover::before {
  width: 100%;
  height: 2px;
}

.services-section {
  position: relative;
  z-index: 1;
}

.services-tab-list .tabs {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.services-tab-list .tabs li {
  margin-bottom: 10px;
}
.services-tab-list .tabs li a {
  display: block;
  background-color: #ffffff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  color: #313e5b;
  font-size: 19px;
  font-weight: 600;
}
.services-tab-list .tabs li a i {
  display: inline-block;
  width: 55px;
  height: 55px;
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
  text-align: center;
  line-height: 55px;
  border-radius: 5px;
  margin-right: 7px;
  color: #ff4800;
}
.services-tab-list .tabs li a i::before {
  font-size: 30px;
}
.services-tab-list .tabs li a:hover {
  background-color: #ff4800;
  color: #ffffff;
}
.services-tab-list .tabs li.current a {
  background-color: #ff4800;
  color: #ffffff;
}
.services-tab-list .tabs li:last-child {
  margin-bottom: 0;
}
.services-tab-list .tab-content {
  padding-left: 30px;
}
.services-tab-list .tab-content .tabs-item .image {
  margin-bottom: 30px;
}
.services-tab-list .tab-content .tabs-item .content .btn {
  margin-top: 5px;
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 50px;
}
.services-details-overview:first-child {
  margin-top: 0;
}
.services-details-overview .services-details-desc {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 700;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 25px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 20px;
  border: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item.active .accordion-content {
  display: block;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item.active .accordion-title {
  border-bottom-color: #eeeeee;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item.active .accordion-title i::before {
  content: "\f068";
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 12px 20px 12px 51px;
  color: #333333;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  font-size: 15px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #ff4800;
  color: #ffffff;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}
.services-details-overview .services-details-image {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

/*================================================
Webinar Area CSS
=================================================*/
.webinar-content {
  margin-left: auto;
  max-width: 555px;
  padding-top: 130px;
  padding-bottom: 130px;
  padding-right: 87px;
}
.webinar-content h2 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
}
.webinar-content p {
  margin-bottom: 15px;
}
.webinar-content .btn {
  margin-top: 10px;
}

.webinar-video-image {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/img/woman.jpg);
}
.webinar-video-image img {
  display: none;
}
.webinar-video-image .video-btn {
  position: absolute;
  left: -45px;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #ff4800;
  border-radius: 50%;
  color: #ffffff;
  z-index: 1;
}
.webinar-video-image .video-btn i::before {
  font-size: 38px;
  padding-left: 5px;
}
.webinar-video-image .video-btn::after, .webinar-video-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ff4800;
}
.webinar-video-image .video-btn::before {
  animation: ripple 2s linear infinite;
}
.webinar-video-image .video-btn::after {
  animation: ripple 2s linear 1s infinite;
}
.webinar-video-image .video-btn:hover, .webinar-video-image .video-btn:focus {
  background-color: #4ac728;
  color: #ffffff;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Projects Area CSS
=================================================*/
.projects-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 80px;
}
.projects-area .section-title {
  text-align: left;
  max-width: 540px;
  margin-left: 0;
  margin-right: 0;
}
.projects-area .container-fluid {
  position: relative;
}

.single-projects-box {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
  background-color: #ffffff;
}
.single-projects-box img {
  border-radius: 5px;
}
.single-projects-box .plus-icon a {
  background-color: #ff4800;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 20px;
  right: 20px;
  transform: scale(0);
  transition: all 0.4s ease-out 0s;
  overflow: hidden;
  border-radius: 50%;
}
.single-projects-box .plus-icon a span {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-projects-box .plus-icon a span::before, .single-projects-box .plus-icon a span::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.single-projects-box .plus-icon a span::after {
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.single-projects-box .plus-icon a:hover {
  background-color: #4ac728;
}
.single-projects-box:hover .plus-icon a {
  transform: scale(1);
}

.projects-slides.owl-theme .owl-stage-outer {
  padding-left: 15px;
  padding-right: 15px;
}
.projects-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  right: 20%;
  top: -115px;
}
.projects-slides.owl-theme .owl-nav [class*=owl-] {
  color: #333333;
  font-size: 15px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  transition: 0.5s;
  position: relative;
  border-radius: 30% 70% 70% 30%/40% 60% 40% 60%;
  z-index: 1;
}
.projects-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  margin-left: 10px;
  border-radius: 70% 30% 30% 70%/60% 40% 60% 40%;
}
.projects-slides.owl-theme .owl-nav [class*=owl-].owl-next::before {
  border-radius: 70% 30% 30% 70%/60% 40% 60% 40%;
}
.projects-slides.owl-theme .owl-nav [class*=owl-]::before {
  width: 100%;
  height: 100%;
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
  border-radius: 30% 70% 70% 30%/40% 60% 40% 60%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s;
}
.projects-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #ff4800;
  color: #ffffff;
}
.projects-slides.owl-theme .owl-nav [class*=owl-]:hover::before {
  opacity: 0;
  visibility: hidden;
}

.circle-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details {
  padding: 40px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  background-color: #ffffff;
}

.projects-details-image {
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.3);
  background-color: #ffffff;
}

.projects-details-info {
  width: 100%;
  height: 100%;
  padding: 25px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  background-color: #ffffff;
}
.projects-details-info ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.projects-details-info ul li {
  margin-bottom: 12px;
  color: #57647c;
}
.projects-details-info ul li:last-child {
  margin-bottom: 0;
}
.projects-details-info ul li a {
  display: inline-block;
  color: #57647c;
}
.projects-details-info ul li a:hover {
  color: #ff4800;
}
.projects-details-info ul li span {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}

.projects-details-desc {
  margin-top: 35px;
}
.projects-details-desc h3 {
  margin-bottom: 12px;
  font-size: 23px;
  font-weight: 700;
}
.projects-details-desc p {
  margin-bottom: 12px;
}
.projects-details-desc blockquote p, .projects-details-desc .blockquote p {
  font-size: 25px;
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  padding-bottom: 70px;
}

.single-team-box {
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  transition: 0.5s;
  text-align: center;
}
.single-team-box .image {
  position: relative;
  overflow: hidden;
}
.single-team-box .image img {
  transition: 0.5s;
}
.single-team-box .image .social {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-decoration: none;
  opacity: 0;
  margin-top: -100px;
  padding: 8px 15px;
  background: #ffffff;
  border-radius: 35px;
  visibility: hidden;
  transition: 0.5s;
}
.single-team-box .image .social a {
  display: inline-block;
  color: #ff4800;
  font-size: 17px;
  margin: 0 7px;
}
.single-team-box .image .social a:hover {
  color: #4ac728;
}
.single-team-box .content {
  padding: 25px;
  position: relative;
  transition: 0.5s;
}
.single-team-box .content h3 {
  margin-bottom: 0;
  transition: 0.5s;
  font-size: 20px;
  font-weight: 700;
}
.single-team-box .content span {
  display: block;
  margin-top: 8px;
  color: #ff4800;
  transition: 0.5s;
  font-family: "Comfortaa", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.single-team-box:hover {
  transform: translateY(-5px);
}
.single-team-box:hover .image img {
  transform: scale(1.2);
}
.single-team-box:hover .image .social {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}
.single-team-box:hover .content {
  background-color: #ff4800;
}
.single-team-box:hover .content h3 {
  color: #ffffff;
}
.single-team-box:hover .content span {
  color: #ffffff;
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
  position: relative;
  z-index: 1;
}

.single-funfacts {
  margin-bottom: 30px;
  text-align: center;
}
.single-funfacts h3 {
  color: #ff4800;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}
.single-funfacts h3 .odometer-auto-theme {
  font-family: "Comfortaa", sans-serif;
}
.single-funfacts p {
  line-height: initial;
  margin-bottom: 0;
}

.contact-cta-box {
  max-width: 600px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  padding: 25px 220px 25px 40px;
  position: relative;
  margin-left: auto;
  margin-top: 40px;
  margin-right: auto;
}
.contact-cta-box h3 {
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 600;
}
.contact-cta-box p {
  line-height: initial;
  margin-top: 10px;
  margin-bottom: 0;
}
.contact-cta-box .btn {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.shape-map1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  padding-bottom: 100px;
}

.customers-partner-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.partner-item {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}
.partner-item a {
  display: block;
  padding: 25px;
  background: #ffffff;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.partner-item a::before, .partner-item a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.partner-item a::before {
  left: 0;
  top: 0;
  border-left: 3px solid #ff4800;
  border-top: 3px solid #ff4800;
}
.partner-item a::after {
  right: 0;
  bottom: 0;
  border-right: 3px solid #ff4800;
  border-bottom: 3px solid #ff4800;
}
.partner-item a img {
  transition: 0.5s;
}
.partner-item a:hover::after, .partner-item a:hover::before {
  width: 40px;
  height: 40px;
  opacity: 1;
  visibility: visible;
}
.partner-item a:hover img {
  transform: scale(0.8);
}
.partner-item:nth-child(6) {
  margin-left: 10%;
}

.partner-section {
  position: relative;
  padding-bottom: 80px;
  z-index: 1;
}

.single-partner-item {
  margin-bottom: 30px;
  text-align: center;
}
.single-partner-item a {
  display: block;
}
.single-partner-item a img {
  width: auto !important;
  display: inline-block !important;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  z-index: 1;
}

.feedback-item {
  position: relative;
  z-index: 3;
  margin-left: auto;
  max-width: 555px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.feedback-item p {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 19px;
  font-style: italic;
}
.feedback-item::before {
  content: "\f113";
  color: #ffffff;
  display: inline-block;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
  font-style: normal;
  font-size: 50px;
  font-family: Flaticon;
}
.feedback-item .client-info {
  margin-top: 20px;
}
.feedback-item .client-info img {
  width: 75px !important;
  display: none !important;
  margin-bottom: 15px;
  border-radius: 5px;
}
.feedback-item .client-info h3 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
}
.feedback-item .client-info span {
  display: block;
  font-weight: 300;
  margin-top: 4px;
  color: #ffffff;
}
.feedback-item::after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #ff4800;
  z-index: -1;
  display: none;
}

.client-image {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.client-image img {
  display: none !important;
  width: auto !important;
}
.client-image.bg1 {
  background-image: url(assets/img/client/client1.jpg);
}
.client-image.bg2 {
  background-image: url(assets/img/client/client2.jpg);
}
.client-image.bg3 {
  background-image: url(assets/img/client/client3.jpg);
}

.feedback-slides.owl-theme .owl-stage .owl-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 68%;
  height: 100%;
  z-index: 1;
  background-image: url(assets/img/1.png);
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
}
.feedback-slides.owl-theme .owl-nav {
  margin-top: 0;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.feedback-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent !important;
  color: #ffffff;
  outline: 0;
  transition: 0.5s;
  font-size: 50px;
}
.feedback-slides.owl-theme .owl-nav [class*=owl-] i::before {
  font-size: 50px;
}
.feedback-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 50px;
}
.feedback-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}
.feedback-slides.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  bottom: 15px;
  right: 0;
}
.feedback-slides.owl-theme .owl-dots .owl-dot {
  outline: 0;
}
.feedback-slides.owl-theme .owl-dots .owl-dot span {
  background-color: #ffffff;
  transition: 0.5s;
  margin: 0 4px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.feedback-slides.owl-theme .owl-dots .owl-dot.active span, .feedback-slides.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #3a3a3a;
}

.testimonials-area {
  background: linear-gradient(to right, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

.single-testimonials-item {
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 45px;
  background-color: #ffffff;
  position: relative;
  padding: 60px 70px 60px 190px;
  border-radius: 5px;
}
.single-testimonials-item .client-info {
  position: absolute;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.single-testimonials-item .client-info img {
  border-radius: 5px;
  border: 2px solid #f4f5fe;
  width: 80px !important;
  height: 80px !important;
  padding: 5px;
  display: inline-block !important;
}
.single-testimonials-item .client-info h3 {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 19px;
  font-weight: 700;
}
.single-testimonials-item .client-info span {
  display: block;
  color: #ff4800;
  margin-top: 5px;
  font-size: 13px;
}
.single-testimonials-item p {
  font-size: 15px;
  margin-bottom: 0;
  color: #4a6f8a;
}
.single-testimonials-item .rating {
  margin-top: 10px;
}
.single-testimonials-item .rating i {
  color: #ff612f;
}
.single-testimonials-item .rating i::before {
  font-size: 14px;
}
.single-testimonials-item::before {
  content: "\f113";
  color: #f5f5f5;
  position: absolute;
  right: 25px;
  bottom: 0;
  font-style: normal;
  font-size: 80px;
  font-family: Flaticon;
}
.single-testimonials-item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 5px;
  bottom: -15px;
  background-color: #ffffff;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
  z-index: -1;
  margin-left: 30px;
  margin-right: 30px;
}

.testimonials-slides {
  max-width: 750px;
  margin: -40px auto 0;
}
.testimonials-slides .single-testimonials-item {
  margin-top: 40px;
}
.testimonials-slides .owl-item {
  padding-left: 50px;
  padding-right: 50px;
}
.testimonials-slides .owl-nav.disabled + .owl-dots {
  line-height: 0.01;
  margin-top: 10px;
  margin-bottom: 30px;
}
.testimonials-slides .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 3px;
  background: transparent;
  transition: 0.5s;
  border: 2px solid #8d95a7;
  border-radius: 50%;
  position: relative;
}
.testimonials-slides .owl-dots .owl-dot span::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ff4800;
  margin: 2px;
  content: "";
  transition: 0.5s;
  border-radius: 50%;
  transform: scale(0);
}
.testimonials-slides .owl-dots .owl-dot:hover span, .testimonials-slides .owl-dots .owl-dot.active span {
  border-color: #ff4800;
  background-color: transparent;
}
.testimonials-slides .owl-dots .owl-dot:hover span::before, .testimonials-slides .owl-dots .owl-dot.active span::before {
  transform: scale(1);
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;
}

.single-pricing-box {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px;
  transition: 0.5s;
  border-radius: 5px;
}
.single-pricing-box .pricing-header h3 {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}
.single-pricing-box .pricing-header p {
  transition: 0.5s;
  line-height: 1.6;
  margin-top: 10px;
  margin-bottom: 0;
}
.single-pricing-box .price {
  font-size: 50px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
  margin-bottom: 10px;
}
.single-pricing-box .price span {
  display: inline-block;
  margin-left: -7px;
  font-size: 20px;
  font-weight: 600;
}
.single-pricing-box .buy-btn {
  margin-bottom: 25px;
}
.single-pricing-box .buy-btn .btn-primary::after {
  background: linear-gradient(to right top, #a3a3a3, #9a9a9a, #909090, #878787, #7e7e7e);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: -1;
  border-radius: 30px;
}
.single-pricing-box .pricing-features {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-pricing-box .pricing-features li {
  margin-bottom: 12px;
  color: #57647c;
  position: relative;
  padding-left: 19px;
}
.single-pricing-box .pricing-features li:last-child {
  margin-bottom: 0;
}
.single-pricing-box .pricing-features li i {
  color: #ff4800;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-pricing-box .pricing-features li i::before {
  font-size: 12px;
}
.single-pricing-box:hover, .single-pricing-box.active {
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border-color: #ffffff;
}
.single-pricing-box:hover .buy-btn .btn-primary::after, .single-pricing-box.active .buy-btn .btn-primary::after {
  opacity: 0;
  visibility: hidden;
}

.tabs-item {
  display: none;
}
.tabs-item:first-child {
  display: block;
}

.pricing-tab .tabs {
  padding-left: 0;
  margin-bottom: 45px;
  list-style-type: none;
  text-align: center;
}
.pricing-tab .tabs li {
  display: inline-block;
}
.pricing-tab .tabs li a {
  background-color: #ffffff;
  padding: 10px 30px 11px;
  margin-left: -2px;
  margin-right: -2px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.pricing-tab .tabs li a:hover {
  color: #ffffff;
  background-color: #333333;
}
.pricing-tab .tabs li.current a {
  color: #ffffff;
  background-color: #333333;
}
.pricing-tab .tabs li:nth-child(1) a {
  border-radius: 5px 0 0 5px;
}
.pricing-tab .tabs li:nth-child(1) a::before {
  border-radius: 5px 0 0 5px;
}
.pricing-tab .tabs li:nth-child(2) a {
  border-radius: 0 5px 5px 0;
}
.pricing-tab .tabs li:nth-child(2) a::before {
  border-radius: 0 5px 5px 0;
}

.pricing-box {
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
  margin-bottom: 30px;
  text-align: center;
  padding: 30px;
  transition: 0.5s;
  border-radius: 5px;
}
.pricing-box .pricing-header h3 {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}
.pricing-box .pricing-header p {
  transition: 0.5s;
  line-height: 1.6;
  margin-top: 10px;
  margin-bottom: 0;
}
.pricing-box .price {
  font-size: 50px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
  margin-bottom: 10px;
}
.pricing-box .price span {
  display: inline-block;
  margin-left: -7px;
  font-size: 20px;
  font-weight: 600;
}
.pricing-box .buy-btn {
  margin-bottom: 25px;
}
.pricing-box .buy-btn .btn-primary::after {
  background: linear-gradient(to right top, #a3a3a3, #9a9a9a, #909090, #878787, #7e7e7e);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: -1;
  border-radius: 30px;
}
.pricing-box .pricing-features {
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}
.pricing-box .pricing-features li {
  margin-bottom: 12px;
  color: #495771;
  position: relative;
  padding-left: 19px;
}
.pricing-box .pricing-features li:last-child {
  margin-bottom: 0;
}
.pricing-box .pricing-features li i {
  color: #ff4800;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pricing-box .pricing-features li i::before {
  font-size: 12px;
}
.pricing-box:hover .buy-btn .btn-primary::after, .pricing-box.active .buy-btn .btn-primary::after {
  opacity: 0;
  visibility: hidden;
}

.pricing-tab.bg-color .tabs li a {
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
}
.pricing-tab.bg-color .tabs li a:hover {
  color: #333333;
  background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
}
.pricing-tab.bg-color .tabs li.current a {
  color: #ffffff;
  background: #333333;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;
}
.blog-area .pagination-area {
  margin-bottom: 30px;
}

.single-blog-post {
  margin-bottom: 30px;
  transition: 0.5s;
  border-radius: 5px;
  background-color: #fafafa;
}
.single-blog-post .entry-thumbnail a img {
  transition: 0.5s;
  border-radius: 5px;
}
.single-blog-post .entry-post-content {
  padding: 20px;
}
.single-blog-post .entry-post-content .entry-meta {
  margin-bottom: 6px;
}
.single-blog-post .entry-post-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-blog-post .entry-post-content .entry-meta ul li {
  display: inline-block;
  position: relative;
  margin-right: 17px;
  color: #57647c;
  font-family: "Comfortaa", sans-serif;
  font-size: 15px;
}
.single-blog-post .entry-post-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  right: -7px;
  top: 50%;
  transform: rotate(14deg) translateY(-50%);
  background: #57647c;
  width: 1px;
  height: 13px;
}
.single-blog-post .entry-post-content .entry-meta ul li a {
  display: inline-block;
  color: #57647c;
}
.single-blog-post .entry-post-content .entry-meta ul li a:hover {
  color: #ff4800;
}
.single-blog-post .entry-post-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.single-blog-post .entry-post-content .entry-meta ul li:last-child::before {
  display: none;
}
.single-blog-post .entry-post-content h3 {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 23px;
  font-weight: 700;
}
.single-blog-post .entry-post-content p {
  margin-top: 8px;
  margin-bottom: 0;
}
.single-blog-post .entry-post-content .learn-more-btn {
  margin-top: 15px;
}
.single-blog-post:hover {
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
  transform: translateY(-5px);
  background-color: #ffffff;
}
.single-blog-post:hover .entry-thumbnail a img {
  opacity: 0.7;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details .article-content {
  margin-top: 30px;
}
.blog-details .article-content .entry-meta {
  margin-bottom: 10px;
}
.blog-details .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-family: "Comfortaa", sans-serif;
}
.blog-details .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #333333;
  margin-right: 25px;
  font-size: 15px;
}
.blog-details .article-content .entry-meta ul li span {
  display: inline-block;
  color: #333333;
  font-weight: 600;
  padding-right: 5px;
}
.blog-details .article-content .entry-meta ul li a {
  display: inline-block;
  color: #57647c;
}
.blog-details .article-content .entry-meta ul li a:hover {
  color: #ff4800;
}
.blog-details .article-content .entry-meta ul li i {
  color: #ff4800;
  margin-right: 2px;
}
.blog-details .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #ff4800;
}
.blog-details .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details .article-content h3 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 700;
}
.blog-details .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details .article-content .article-features-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 15px;
  margin-left: 20px;
}
.blog-details .article-content .article-features-list li {
  margin-bottom: 12px;
  color: #57647c;
  position: relative;
  padding-left: 15px;
}
.blog-details .article-content .article-features-list li::before {
  background: #ff4800;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
}
.blog-details .article-content .article-features-list li:last-child {
  margin-bottom: 0;
}
.blog-details .article-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-top: 30px;
}
.blog-details .article-footer .article-tags {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-tags span {
  display: inline-block;
  color: #333333;
  font-size: 20px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.blog-details .article-footer .article-tags a {
  display: inline-block;
  color: #818181;
}
.blog-details .article-footer .article-tags a:hover {
  color: #ff4800;
}
.blog-details .article-footer .article-share {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details .article-footer .article-share .social li {
  display: inline-block;
  margin-left: 5px;
}
.blog-details .article-footer .article-share .social li a {
  color: #333333;
  background-color: #f7f7f7;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  font-size: 13px;
}
.blog-details .article-footer .article-share .social li a:hover {
  color: #ffffff;
  background-color: #ff4800;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  font-family: "Comfortaa", sans-serif;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p, .blockquote p {
  color: #333333;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
}
blockquote cite, .blockquote cite {
  display: none;
}
blockquote::before, .blockquote::before {
  color: #efefef;
  content: "\f10d";
  position: absolute;
  left: 50px;
  top: -60px;
  z-index: -1;
  font-family: "Font Awesome 5 Free";
  font-size: 140px;
  font-weight: 900;
}

.comments-area {
  margin-top: 30px;
}
.comments-area .comments-title {
  line-height: initial;
  margin-bottom: 30px;
  font-size: 23px;
  font-weight: 700;
}
.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.comments-area .children {
  margin-left: 20px;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #eeeeee;
  color: #57647c;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #ff4800;
  border-color: #ff4800;
}
.comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  color: #57647c;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
.comments-area .comment-metadata a {
  color: #57647c;
}
.comments-area .comment-metadata a:hover {
  color: #ff4800;
}
.comments-area .comment-content p {
  font-size: 14px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 700;
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  font-size: 15px;
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: block;
  margin-bottom: 5px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}
.comments-area .comment-respond input[type=date], .comments-area .comment-respond input[type=time], .comments-area .comment-respond input[type=datetime-local], .comments-area .comment-respond input[type=week], .comments-area .comment-respond input[type=month], .comments-area .comment-respond input[type=text], .comments-area .comment-respond input[type=email], .comments-area .comment-respond input[type=url], .comments-area .comment-respond input[type=password], .comments-area .comment-respond input[type=search], .comments-area .comment-respond input[type=tel], .comments-area .comment-respond input[type=number], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.comments-area .comment-respond input[type=date]:focus, .comments-area .comment-respond input[type=time]:focus, .comments-area .comment-respond input[type=datetime-local]:focus, .comments-area .comment-respond input[type=week]:focus, .comments-area .comment-respond input[type=month]:focus, .comments-area .comment-respond input[type=text]:focus, .comments-area .comment-respond input[type=email]:focus, .comments-area .comment-respond input[type=url]:focus, .comments-area .comment-respond input[type=password]:focus, .comments-area .comment-respond input[type=search]:focus, .comments-area .comment-respond input[type=tel]:focus, .comments-area .comment-respond input[type=number]:focus, .comments-area .comment-respond textarea:focus {
  border-color: #ff4800;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #57647c;
  font-weight: normal;
  font-weight: 500;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background-color: #ff4800;
  border: none;
  color: #ffffff;
  padding: 10.5px 25px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;
  font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #4ac728;
}

/*================================================
Free Trial Area CSS
=================================================*/
.free-trial-area {
  position: relative;
  z-index: 1;
}
.free-trial-area::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 68%;
  height: 100%;
  z-index: 1;
  background-image: url(assets/img/2.png);
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
}

.free-trial-content {
  position: relative;
  z-index: 2;
  max-width: 555px;
  padding-left: 30px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.free-trial-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
}
.free-trial-content form {
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;
}
.free-trial-content form .input-newsletter {
  display: block;
  width: 72%;
  height: 45px;
  color: #ffffff;
  border: none;
  outline: 0;
  background-color: #333333;
  border-radius: 30px;
  padding-left: 20px;
}
.free-trial-content form .input-newsletter::placeholder {
  color: #d5d5d5;
}
.free-trial-content form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  border: none;
  padding: 0 30px;
  line-height: 45px;
  background-color: #ffffff;
  border-radius: 30px;
  transition: 0.5s;
  color: #ff4800;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.free-trial-content form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.free-trial-content p {
  color: #ffffff;
  margin-bottom: 0;
  max-width: 350px;
}
.free-trial-content::after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #ff4800;
  z-index: -1;
  display: none;
}

.free-trial-image {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/img/woman2.jpg);
}
.free-trial-image img {
  display: none;
}

.free-trial-section {
  position: relative;
  z-index: 1;
}
.free-trial-section::before {
  width: 40%;
  height: 100%;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to left bottom, #f8f9fd, #fafafd, #fcfcfe, #fdfdfe, #ffffff);
  content: "";
}

.free-trial-img {
  margin-left: -15px;
  text-align: center;
}

.free-trial-text {
  padding-left: 15px;
}
.free-trial-text h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
}
.free-trial-text form {
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;
}
.free-trial-text form .input-newsletter {
  display: block;
  width: 72%;
  height: 45px;
  color: #ffffff;
  border: none;
  outline: 0;
  background-color: #333333;
  border-radius: 30px;
  padding-left: 20px;
}
.free-trial-text form .input-newsletter::placeholder {
  color: #d5d5d5;
  transition: 0.5s;
}
.free-trial-text form .input-newsletter:focus::placeholder {
  color: transparent;
}
.free-trial-text form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 45px;
  border: none;
  padding: 0 30px;
  line-height: 45px;
  background-color: #ff4800;
  border-radius: 30px;
  transition: 0.5s;
  color: #ffffff;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.free-trial-text form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.free-trial-text p {
  margin-bottom: 0;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  padding-top: 210px;
  padding-bottom: 140px;
  background-color: #000000;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-title-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000000;
  opacity: 0.6;
}
.page-title-area.item-bg1 {
  background-image: url(assets/img/page-title-bg1.jpg);
}
.page-title-area.item-bg2 {
  background-image: url(assets/img/page-title-bg2.jpg);
}
.page-title-area.item-bg3 {
  background-image: url(assets/img/page-title-bg3.jpg);
}

.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  font-family: "Comfortaa", sans-serif;
  margin-top: 10px;
  margin-bottom: 0;
}
.page-title-content ul li {
  display: inline-block;
  color: #ffffff;
  margin-right: 15px;
  margin-left: 10px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
}
.page-title-content ul li a {
  color: #ff4800;
}
.page-title-content ul li a:hover {
  color: #ff4800;
}
.page-title-content ul li::before {
  content: "\f054";
  position: absolute;
  right: -15px;
  top: 6px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
}
.page-title-content ul li:last-child {
  margin-right: 0;
}
.page-title-content ul li:last-child::before {
  display: none;
}
.page-title-content ul li:first-child {
  margin-left: 0;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 30px;
  text-align: center;
  font-family: "Comfortaa", sans-serif;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 40px;
  color: #333333;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 16px;
  font-weight: 700;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: #ff4800;
  color: #ffffff;
  box-shadow: unset;
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 30px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
  font-size: 20px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: #ff4800;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.widget-area .widget_search {
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: #ffffff;
  padding: 15px;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.widget-area .widget_search form .search-field:focus {
  border-color: #ff4800;
}
.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #ff4800;
  transition: 0.5s;
}
.widget-area .widget_search form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.widget-area .widget_kirin_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_kirin_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_kirin_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_kirin_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.widget-area .widget_kirin_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_kirin_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog/blog1.jpg);
}
.widget-area .widget_kirin_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog/blog2.jpg);
}
.widget-area .widget_kirin_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog/blog3.jpg);
}
.widget-area .widget_kirin_posts_thumb .item .info {
  overflow: hidden;
}
.widget-area .widget_kirin_posts_thumb .item .info time {
  display: block;
  color: #57647c;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 11px;
}
.widget-area .widget_kirin_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 16px;
  font-weight: 700;
}
.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #57647c;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 14.5px;
  font-family: "Comfortaa", sans-serif;
}
.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_entries ul li::before {
  background: #ff4800;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
}
.widget-area .widget_recent_entries ul li a {
  color: #57647c;
}
.widget-area .widget_recent_entries ul li a:hover {
  color: #ff4800;
}
.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #57647c;
  margin-top: 4px;
}
.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #333333;
  padding-left: 14px;
  line-height: 1.5;
  font-size: 14.5px;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}
.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_comments ul li::before {
  background: #ff4800;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_recent_comments ul li span {
  display: inline-block;
}
.widget-area .widget_recent_comments ul li a {
  color: #57647c;
  display: inline-block;
}
.widget-area .widget_recent_comments ul li a:hover {
  color: #ff4800;
}
.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  color: #57647c;
  font-size: 14.5px;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}
.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_archive ul li::before {
  background: #ff4800;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_archive ul li a {
  color: #57647c;
}
.widget-area .widget_archive ul li a:hover {
  color: #ff4800;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #57647c;
  padding-left: 14px;
  font-size: 14.5px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: #ff4800;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: #57647c;
}
.widget-area .widget_categories ul li a:hover {
  color: #ff4800;
}
.widget-area .widget_categories ul li .post-count {
  float: right;
}
.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: #57647c;
  padding-left: 14px;
  font-size: 14.5px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
}
.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_meta ul li::before {
  background: #ff4800;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_meta ul li a {
  color: #57647c;
}
.widget-area .widget_meta ul li a:hover {
  color: #ff4800;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}
.widget-area .tagcloud a {
  display: inline-block;
  color: #57647c;
  font-weight: 600;
  font-size: 12.5px !important;
  padding: 6px 13px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #ff4800;
  border-color: #ff4800;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  background-color: #333333;
  padding-bottom: 100px;
  padding-top: 150px;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 17px;
}
.error-content p {
  max-width: 520px;
  color: #ffffff;
  margin: 0 auto 20px;
}
.error-content .btn-primary {
  background-color: #ffffff;
  color: #333333;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-content .section-title {
  text-align: left;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
}
.faq-content .section-title p {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 15px;
  border: 0;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item.active .accordion-content {
  display: block;
}
.faq-accordion .accordion .accordion-item.active .accordion-title {
  border-bottom-color: #eeeeee;
}
.faq-accordion .accordion .accordion-item.active .accordion-title i::before {
  content: "\f068";
}
.faq-accordion .accordion .accordion-title {
  padding: 12px 20px 12px 51px;
  color: #333333;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  font-size: 15px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #ff4800;
  color: #ffffff;
}
.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}

.faq-contact {
  margin-top: 100px;
}

.faq-contact-image img {
  max-width: 300px;
  width: 100%;
}

.faq-contact-form {
  text-align: center;
}
.faq-contact-form form {
  padding-left: 50px;
}
.faq-contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  height: 50px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
}
.faq-contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.faq-contact-form form .row .col-lg-12, .faq-contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.faq-contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.faq-contact-form form .btn {
  padding: 13px 35px 15px;
  font-size: 17px;
  margin-top: 8px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.faq-contact-form form .btn.disabled {
  cursor: not-allowed !important;
}
.faq-contact-form form .btn.disabled:hover::before, .faq-contact-form form .btn.disabled:focus::before {
  opacity: 1;
  visibility: visible;
}
.faq-contact-form form .btn.disabled:hover::after, .faq-contact-form form .btn.disabled:focus::after {
  opacity: 0;
  visibility: hidden;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
}
.coming-soon-area .coming-soon-content {
  height: 100vh;
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
}
.coming-soon-area .coming-soon-content .logo {
  position: absolute;
  left: 100px;
  top: 30px;
}
.coming-soon-area .coming-soon-content h3 {
  line-height: 1.3;
  margin-bottom: 25px;
  font-size: 40px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-content form {
  max-width: 520px;
}
.coming-soon-area .coming-soon-content form .form-group {
  margin-bottom: 25px;
}
.coming-soon-area .coming-soon-content form .form-control {
  height: 50px;
  border: none;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.coming-soon-area .coming-soon-content form .btn {
  display: block;
  width: 100%;
  padding: 15px 28px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.coming-soon-area .coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 15px;
}
.coming-soon-area .coming-soon-content .social {
  position: absolute;
  left: 100px;
  bottom: 30px;
  text-align: center;
}
.coming-soon-area .coming-soon-content .social ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.coming-soon-area .coming-soon-content .social ul li {
  display: inline-block;
  margin-right: 5px;
}
.coming-soon-area .coming-soon-content .social ul li:last-child {
  margin-right: 0;
}
.coming-soon-area .coming-soon-content .social ul li a {
  width: 35px;
  height: 35px;
  line-height: 37px;
  border-radius: 50%;
  background-color: #dddddd;
  color: #333333;
  font-size: 14px;
}
.coming-soon-area .coming-soon-content .social ul li a.twitter {
  background-color: #1da1f2;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.youtube {
  background-color: #ff0000;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.facebook {
  background-color: #3b5998;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.linkedin {
  background-color: #0077b5;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.instagram {
  background-color: #c13584;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a:hover {
  background-color: #ff4800;
  color: #ffffff;
}
.coming-soon-area .coming-soon-time {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  z-index: 1;
  background-image: url(assets/img/coming-soon-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area .coming-soon-time img {
  display: none;
}
.coming-soon-area .coming-soon-time #timer {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-area .coming-soon-time #timer div {
  width: 155px;
  height: 155px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  line-height: initial;
  padding-top: 37px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 45px;
  font-weight: 700;
  font-family: "Comfortaa", sans-serif;
}
.coming-soon-area .coming-soon-time #timer div span {
  text-transform: uppercase;
  display: block;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 600;
}
.coming-soon-area .coming-soon-time::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
  z-index: -1;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area .section-title {
  margin-bottom: 40px;
}

.contact-image img {
  max-width: 300px;
  width: 100%;
}

.contact-form {
  text-align: center;
}
.contact-form form {
  padding-left: 50px;
}
.contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  height: 50px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
}
.contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.contact-form form .row .col-lg-12, .contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.contact-form form .btn {
  padding: 13px 35px 15px;
  font-size: 17px;
  margin-top: 10px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5);
}
.contact-form form .btn.disabled {
  cursor: not-allowed !important;
}
.contact-form form .btn.disabled:hover::before, .contact-form form .btn.disabled:focus::before {
  opacity: 1;
  visibility: visible;
}
.contact-form form .btn.disabled:hover::after, .contact-form form .btn.disabled:focus::after {
  opacity: 0;
  visibility: hidden;
}

.contact-info {
  text-align: center;
  margin-top: 60px;
}
.contact-info .contact-info-content h3 {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 700;
}
.contact-info .contact-info-content h2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}
.contact-info .contact-info-content h2 a {
  display: inline-block;
  color: #ff4800;
}
.contact-info .contact-info-content h2 a:hover {
  color: #4ac728;
}
.contact-info .contact-info-content h2 a:not(:first-child) {
  color: #4ac728;
}
.contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #ff4800;
}
.contact-info .contact-info-content h2 span {
  display: block;
  color: #57647c;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 2px;
}
.contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
}
.contact-info .contact-info-content .social li a i {
  font-size: 14px;
}
.contact-info .contact-info-content .social li a:hover {
  color: #ffffff;
  border-color: #ff4800;
  background-color: #ff4800;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  background-color: #15171a;
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .logo {
  margin-bottom: 20px;
}
.single-footer-widget .logo p {
  color: #ffffff;
  margin-top: 18px;
  margin-bottom: 0;
}
.single-footer-widget p {
  color: #ffffff;
  font-size: 14px;
}
.single-footer-widget .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .social li {
  display: inline-block;
  transition: 0.5s;
  margin-right: 5px;
}
.single-footer-widget .social li a {
  width: 35px;
  height: 35px;
  background-color: #333333;
  line-height: 35px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}
.single-footer-widget .social li a i::before {
  font-size: 13px;
}
.single-footer-widget .social li a:hover {
  background-color: #ff4800;
  color: #ffffff;
}
.single-footer-widget h3 {
  margin-bottom: 20px;
  color: #ffffff;
  position: relative;
  padding-bottom: 10px;
  font-size: 23px;
  font-weight: 700;
}
.single-footer-widget h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background: #ff4800;
}
.single-footer-widget .footer-services-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .footer-services-list li {
  margin-bottom: 12px;
  color: #ffffff;
  font-size: 14px;
}
.single-footer-widget .footer-services-list li a {
  display: inline-block;
  color: #ffffff;
}
.single-footer-widget .footer-services-list li a:hover {
  color: #ff4800;
}
.single-footer-widget .footer-services-list li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .quick-links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .quick-links-list li {
  margin-bottom: 12px;
  color: #ffffff;
  font-size: 14px;
}
.single-footer-widget .quick-links-list li a {
  display: inline-block;
  color: #ffffff;
}
.single-footer-widget .quick-links-list li a:hover {
  color: #ff4800;
}
.single-footer-widget .quick-links-list li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-contact-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .footer-contact-list li {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.8;
}
.single-footer-widget .footer-contact-list li a {
  display: inline-block;
  color: #ffffff;
}
.single-footer-widget .footer-contact-list li a:hover {
  color: #ff4800;
}
.single-footer-widget .footer-contact-list li span {
  font-weight: 700;
  margin-right: 5px;
}
.single-footer-widget .footer-contact-list li:last-child {
  margin-bottom: 0;
}

.copyright-area {
  background-color: #000000;
  margin-top: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copyright-area p {
  color: #ffffff;
}
.copyright-area p a {
  display: inline-block;
  color: #ffffff;
  font-weight: 600;
}
.copyright-area p a:hover {
  color: #ff4800;
}
.copyright-area ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: right;
}
.copyright-area ul li {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  color: #ffffff;
  font-size: 14px;
  line-height: initial;
}
.copyright-area ul li a {
  color: #ffffff;
}
.copyright-area ul li a:hover {
  color: #ff4800;
}
.copyright-area ul li::before {
  content: "";
  width: 4px;
  height: 1px;
  background: #ffffff;
  left: -11px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.copyright-area ul li:first-child {
  margin-left: 0;
}
.copyright-area ul li:first-child::before {
  display: none;
}

.circle-map {
  position: absolute;
  top: 20%;
  right: 18%;
  z-index: -1;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: -1;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: run 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background: #ff4800;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top.active {
  top: 98%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0.5s;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #4ac728;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover, .go-top:focus {
  color: #ffffff;
}
.go-top:hover::before, .go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child, .go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child, .go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area.navbar-style-three.p-relative {
  position: relative;
}
.navbar-area.navbar-style-three.p-relative .kirin-nav .navbar .others-options .btn-primary {
  background-color: #ff4800;
  color: #ffffff;
}
.navbar-area.navbar-style-three.p-relative.is-sticky {
  position: fixed;
}

/*================================================
Main Banner Area CSS
=================================================*/
.home-slides .main-banner {
  height: auto;
  padding-top: 310px;
  padding-bottom: 220px;
}
.home-slides .main-banner .main-banner-content {
  overflow: hidden;
  margin-left: 0;
  margin-top: 0;
}
.home-slides .main-banner .main-banner-content p {
  margin-top: 30px;
}
.home-slides .main-banner.item-bg1 {
  background-image: url(assets/img/main-banner-bg1.jpg);
}
.home-slides .main-banner.item-bg2 {
  background-image: url(assets/img/main-banner-bg2.jpg);
}
.home-slides.owl-theme .owl-nav {
  margin-top: 0;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.home-slides.owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  color: #333333;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  transition: 0.5s;
  font-size: unset;
  border-radius: 0;
  overflow: hidden;
}
.home-slides.owl-theme .owl-nav [class*=owl-] i::before {
  font-size: 25px;
}
.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 25px;
}
.home-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #ff4800;
  color: #ffffff;
}
.home-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.startup-banner-area {
  padding-left: 30px;
  padding-right: 30px;
}

.startup-banner-inner {
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/img/startup-banner.jpg);
  padding-top: 160px;
  padding-bottom: 160px;
}

.startup-banner-content {
  max-width: 720px;
}
.startup-banner-content h1 {
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 60px;
  font-weight: 700;
}
.startup-banner-content p {
  max-width: 600px;
  color: #434343;
  font-weight: 500;
  font-size: 15px;
  margin-top: 25px;
  margin-bottom: 0;
}
.startup-banner-content .btn-box {
  margin-top: 30px;
}
.startup-banner-content .btn-box .optional-btn {
  display: inline-block;
  color: #333333;
  position: relative;
  margin-left: 20px;
  font-family: "Comfortaa", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.startup-banner-content .btn-box .optional-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #333333;
  transition: 0.5s;
}
.startup-banner-content .btn-box .optional-btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ff4800;
  transition: 0.5s;
}
.startup-banner-content .btn-box .optional-btn:hover {
  color: #333333;
}
.startup-banner-content .btn-box .optional-btn:hover::before {
  width: 0;
}
.startup-banner-content .btn-box .optional-btn:hover::after {
  width: 100%;
  right: auto;
  left: 0;
}

/*================================================
Main Services Area CSS
=================================================*/
.main-services-area {
  padding-bottom: 80px;
}

.single-main-services-box {
  margin-bottom: 30px;
  border: 1px solid #ffd1bf;
  padding: 30px;
  transition: 0.5s;
  box-shadow: 5px 5px #ffd1bf;
}
.single-main-services-box .icon {
  margin-bottom: 22px;
  line-height: 1;
  color: #ff4800;
}
.single-main-services-box .icon i::before {
  font-size: 50px;
}
.single-main-services-box h3 {
  margin-bottom: 12px;
  font-size: 23px;
  font-weight: 700;
}
.single-main-services-box .link-btn {
  display: inline-block;
  font-weight: 600;
  color: #ff4800;
}
.single-main-services-box:hover {
  border-color: #ff4800;
  box-shadow: 5px 5px #ff4800;
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-content h2 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
}
.why-choose-us-content .btn {
  margin-top: 25px;
}
.why-choose-us-content .row {
  margin-top: -15px;
}

.single-why-choose-us-box {
  margin-top: 30px;
}
.single-why-choose-us-box h3 {
  margin-bottom: 12px;
  font-size: 21px;
  font-weight: 700;
}

/*================================================
Solutions Area CSS
=================================================*/
.solutions-area {
  padding-bottom: 80px;
  background-color: #ffefe9;
  background-image: url(assets/img/solutions-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-solutions-box {
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: 0.5s;
}
.single-solutions-box .icon {
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  transition: 0.5s;
  text-align: center;
  color: #ff4800;
}
.single-solutions-box .icon i::before {
  font-size: 35px;
}
.single-solutions-box h3 {
  margin-bottom: 12px;
  font-size: 23px;
  font-weight: 700;
}
.single-solutions-box.active, .single-solutions-box:hover {
  border-color: #ff4800;
  box-shadow: 5px 5px #ff4800;
  background-color: #ffffff;
}
.single-solutions-box.active .icon, .single-solutions-box:hover .icon {
  background-color: #ff4800;
  color: #ffffff;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-section .section-title {
  margin-bottom: 50px;
}
.testimonials-section .section-title .content {
  text-align: right;
  padding-right: 30px;
  position: relative;
}
.testimonials-section .section-title .content::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #ffe3d8;
}
.testimonials-section .section-title .icon {
  text-align: left;
  margin-bottom: -15px;
  padding-left: 30px;
}
.testimonials-section .section-title .icon i {
  color: #ff4800;
  line-height: 1;
}
.testimonials-section .section-title .icon i::before {
  line-height: 1;
  font-size: 90px;
}
.testimonials-section .testimonials-slides {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}
.testimonials-section .testimonials-slides .owl-nav.disabled + .owl-dots {
  margin-bottom: 0;
  margin-top: 0;
}

.single-testimonials-box {
  text-align: center;
  margin-bottom: 30px;
}
.single-testimonials-box p {
  color: #333333;
  margin-bottom: 0;
  font-size: 22px;
}
.single-testimonials-box .client-info {
  margin-top: 25px;
}
.single-testimonials-box .client-info h3 {
  margin-bottom: 9px;
  font-size: 20px;
  font-weight: 700;
}
.single-testimonials-box .client-info span {
  color: #ff4800;
}

/*================================================
Webinar Area CSS
=================================================*/
.webinar-inner {
  background-color: #f4f0e6;
  padding-left: 15px;
  padding-right: 125px;
  padding-top: 10px;
}
.webinar-inner .content h2 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
}
.webinar-inner .content p {
  max-width: 540px;
}
.webinar-inner .content .btn {
  margin-top: 5px;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-bg-color.pricing-tab .tabs li a {
  background-color: #ffefe9;
}
.pricing-bg-color.pricing-tab .tabs li.current a, .pricing-bg-color.pricing-tab .tabs li:hover a {
  background-color: #333333;
}
.pricing-bg-color .single-pricing-box {
  border: 1px solid #ffd1bf;
  box-shadow: 5px 5px #ffd1bf;
  border-radius: 0;
  text-align: center;
}
.pricing-bg-color .single-pricing-box.active, .pricing-bg-color .single-pricing-box:hover {
  border-color: #ff4800;
  box-shadow: 5px 5px #ff4800;
}
.pricing-bg-color .single-pricing-box .pricing-features {
  text-align: left;
}

/*================================================
Newsletter Area CSS
=================================================*/
.newsletter-inner {
  background-color: #ffffff;
  border: 1px solid #ffd1bf;
  box-shadow: 5px 5px #ffd1bf;
  transition: 0.5s;
  padding-left: 15px;
  padding-right: 100px;
  padding-top: 5px;
}
.newsletter-inner .content {
  padding-left: 30px;
}
.newsletter-inner .content h2 {
  margin-bottom: 25px;
  font-size: 40px;
  font-weight: 700;
}
.newsletter-inner .content form {
  margin-bottom: 15px;
}
.newsletter-inner .content form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background-color: #ffdacb !important;
  color: #333333;
  outline: 0 !important;
  box-shadow: unset !important;
  padding-left: 20px;
  border-radius: 30px;
}
.newsletter-inner .content form .input-newsletter::placeholder {
  color: #464646;
  font-weight: 500;
  transition: 0.5s;
}
.newsletter-inner .content form .input-newsletter:focus::placeholder {
  color: transparent;
}
.newsletter-inner .content form button {
  display: block;
  width: 100%;
  margin-top: 10px;
  border-radius: 30px;
  border: none;
  line-height: initial;
  border-radius: 30px;
  transition: 0.5s;
  padding: 13px 30px;
  color: #ffffff;
  background-color: #ff4800;
  font-size: 16px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
}
.newsletter-inner .content form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.newsletter-inner:hover {
  border-color: #ff4800;
  box-shadow: 5px 5px #ff4800;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.section-title.with-underline-text {
  max-width: 700px;
}
.section-title.with-underline-text h2 b {
  display: inline-block;
  text-decoration-line: underline;
  color: #437FEC;
  font-weight: bold;
}
.section-title.with-underline-text p {
  max-width: 100%;
}

/*================================================
Topbar Wrap Area CSS
=================================================*/
.topbar-wrap-area {
  background-color: #ffffff;
  box-shadow: 0px 6px 15px rgba(71, 113, 188, 0.07);
  padding-top: 15px;
  padding-bottom: 15px;
}
.topbar-wrap-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.topbar-content span {
  font-size: 14px;
  font-weight: 600;
}
.topbar-content span a {
  color: #ff4800;
  border-bottom: 1px solid #ff4800;
  display: inline-block;
}
.topbar-content span a:hover {
  color: #4ac728;
  border-bottom: 1px solid #4ac728;
}

.topbar-action-list {
  padding: 0;
  margin-bottom: 0;
  text-align: end;
}
.topbar-action-list li {
  font-size: 14px;
  font-weight: 600;
  list-style-type: none;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  padding-left: 20px;
}
.topbar-action-list li::before {
  content: "";
  position: absolute;
  top: 1px;
  right: -20px;
  width: 1px;
  height: 20px;
  border: 2px solid #E4E7FB;
}
.topbar-action-list li:last-child {
  margin-right: 0;
}
.topbar-action-list li:last-child::before {
  display: none;
}
.topbar-action-list li:last-child i {
  top: 6.5px;
}
.topbar-action-list li:first-child i {
  left: 2px;
}
.topbar-action-list li i {
  position: absolute;
  left: 0;
  top: 5px;
  color: #ff4800;
  font-size: 12.5px;
}
.topbar-action-list li a:hover {
  color: #4ac728;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area-with-position-relative {
  position: relative;
  background: linear-gradient(180deg, #F5FCFE 3.31%, #F6FCFF 100%);
}
.navbar-area-with-position-relative.with-white-color {
  background: #ffffff;
}
.navbar-area-with-position-relative .kirin-nav {
  padding-left: 0;
  padding-right: 0;
}
.navbar-area-with-position-relative .kirin-nav .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item a {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item a:hover, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item a:focus, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff4800;
}
.navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item:hover a, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item:focus a, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item.active a {
  color: #ff4800;
}
.navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area-with-position-relative .kirin-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff4800;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .cart-btn {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .cart-btn:hover {
  color: #ff4800;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .btn-primary {
  background-color: #ff4800;
  color: #ffffff;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .btn-primary:hover {
  color: #ffffff !important;
  background-color: #4ac728 !important;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .option-item {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .option-item .search-btn {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .option-item .search-btn:hover {
  color: #ff4800;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .option-item .close-btn {
  color: #333333;
}
.navbar-area-with-position-relative .kirin-nav .navbar .others-options .option-item .close-btn:hover {
  color: #ff4800;
}

/*================================================
AI Main Banner Area CSS
=================================================*/
.ai-main-banner-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(180deg, #F5FCFE 3.31%, #F6FCFF 100%);
  padding-top: 120px;
  padding-bottom: 120px;
}
.ai-main-banner-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.ai-main-banner-content {
  text-align: center;
  max-width: 750px;
  margin: auto;
  position: relative;
  top: -10px;
}
.ai-main-banner-content h1 {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 18.5px;
}
.ai-main-banner-content h1 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.ai-main-banner-content p {
  max-width: 575px;
  margin: auto;
  font-size: 16px;
}
.ai-main-banner-content .banner-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.ai-main-banner-content .banner-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.ai-main-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.ai-main-banner-content .banner-btn li .video-btn {
  margin-left: 5px;
  font-size: 14.5px;
  font-weight: 600;
  color: #333333;
}
.ai-main-banner-content .banner-btn li .video-btn i {
  background: linear-gradient(320.56deg, #074ECE 0%, #4D8CFF 100%);
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.12);
  height: 40px;
  width: 40px;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
}

.ai-main-banner-image {
  position: absolute;
  left: 0;
  bottom: 10%;
  transform: translateY(-10%);
  z-index: -1;
  width: 30%;
}
.ai-main-banner-image .circle-shape {
  width: 450px;
  height: 300px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -150px;
}
.ai-main-banner-image.right-image {
  right: 0;
  left: auto;
}
.ai-main-banner-image.right-image .circle-shape {
  right: -150px;
  left: auto;
}

.ai-main-banner-shape-1 {
  position: absolute;
  top: 5%;
  left: 22%;
  transform: translateY(-5%) translateX(-22%);
  z-index: -1;
}
.ai-main-banner-shape-1 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ai-main-banner-shape-2 {
  position: absolute;
  top: 10%;
  right: 25%;
  transform: translateY(-10%) translateX(-25%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

.ai-main-banner-shape-3 {
  position: absolute;
  bottom: 10%;
  left: 35%;
  transform: translateY(-10%) translateX(-35%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-wrap-area {
  position: relative;
  z-index: 1;
}
.fun-facts-wrap-area::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, #F5FCFE 3.31%, #F6FCFF 100%);
}
.fun-facts-wrap-area.without-before-color::before {
  display: none;
}

.fun-facts-wrap-area-inner-box {
  background: linear-gradient(90deg, #DD24ED 0%, #7609EA 97.61%);
  box-shadow: -3px 8px 20px rgba(80, 80, 161, 0.07);
  padding-left: 100px;
  padding-right: 100px;
}
.fun-facts-wrap-area-inner-box.gradient-optional-color {
  background: linear-gradient(90deg, #61967D 0%, #4A4493 100%);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.fun-facts-wrap-area-inner-box.gradient-optional-color .fun-facts-inner-shape {
  position: absolute;
  top: 30px;
  left: 30%;
  transform: translateX(-30%);
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.single-funfacts-card {
  margin-bottom: 30px;
}
.single-funfacts-card h3 {
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 0;
}
.single-funfacts-card h3 .odometer-auto-theme {
  font-family: "Comfortaa", sans-serif;
}
.single-funfacts-card p {
  color: #ffffff;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
}

.fun-facts-wrap-shape {
  position: absolute;
  bottom: -35px;
  right: 75px;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}

/*================================================
Top Featured Area CSS
=================================================*/
.top-featured-content {
  margin-bottom: 30px;
}
.top-featured-content h2 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.top-featured-content h2 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.top-featured-content p {
  margin-bottom: 0;
}
.top-featured-content .featured-btn {
  margin-top: 20px;
}

.top-featured-card {
  margin-bottom: 30px;
  background: #F8FBFF;
  padding: 35px 30px;
  border-radius: 5px;
}
.top-featured-card .image-icon {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: 0.5s;
  position: relative;
  z-index: 1;
}
.top-featured-card .image-icon::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(0deg, #FFC2CC 0%, #FFF2F4 100%);
  z-index: -1;
  transition: 0.5s;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}
.top-featured-card h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}
.top-featured-card .learn-btn {
  background-color: #ffffff;
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: #57647c;
  font-weight: 600;
  margin-top: 22px;
}
.top-featured-card:hover .image-icon {
  animation: jackInTheBox 1s;
}
.top-featured-card:hover .image-icon::before {
  opacity: 1;
  visibility: visible;
}
.top-featured-card:hover .learn-btn {
  color: #ff4800;
}

.top-featured-btn {
  text-align: center;
  margin-top: 10px;
}

.top-featured-inner-box {
  position: relative;
  z-index: 1;
  margin-top: -100px;
}

/*================================================
AI About Area CSS
=================================================*/
.ai-about-content {
  padding-left: 45px;
}
.ai-about-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.ai-about-content h3 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.ai-about-content p {
  margin-bottom: 0;
}
.ai-about-content .about-list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
  max-width: 450px;
}
.ai-about-content .about-list li {
  list-style-type: none;
  background-color: #ffffff;
  box-shadow: -3px 4px 30px rgba(67, 127, 236, 0.08);
  padding: 14px 15px 15px 42px;
  border-radius: 5px;
  margin-bottom: 18px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #57647c;
}
.ai-about-content .about-list li:last-child {
  margin-bottom: 0;
}
.ai-about-content .about-list li i {
  font-size: 14px;
  color: #03B5A9;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.ai-about-content .about-btn {
  margin-top: 25px;
}
.ai-about-content .about-btn .btn-primary {
  background-color: transparent;
  border: 2px solid #ff4800;
  color: #57647c;
}
.ai-about-content .about-btn .btn-primary:hover {
  border: 2px solid #4ac728;
}

.ai-about-image {
  position: relative;
  z-index: 1;
  text-align: center;
}
.ai-about-image .circle-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.ai-about-image .vector-shape {
  position: absolute;
  left: 40px;
  top: 30px;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}

/*================================================
AI Services Area CSS
=================================================*/
.ai-services-area {
  background: #F7FBFF;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.ai-services-area.with-linear-gradient-color {
  background: linear-gradient(269.49deg, #DD24ED 0.44%, #7609EA 99.58%);
}
.ai-services-area.with-linear-gradient-color .section-title h2 {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-color .section-title h2 b {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-color .section-title p {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-color .single-services-card {
  border-radius: 0;
}
.ai-services-area.with-linear-gradient-optional-color {
  background: linear-gradient(90deg, #61967D 0%, #4A4493 100%);
  position: relative;
  z-index: 1;
}
.ai-services-area.with-linear-gradient-optional-color::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 250px;
  background: #ffffff;
}
.ai-services-area.with-linear-gradient-optional-color .section-title h2 {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-optional-color .section-title h2 b {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-optional-color .section-title p {
  color: #ffffff;
}
.ai-services-area.with-linear-gradient-optional-color .single-services-card {
  border-radius: 0;
  padding: 35px 30px;
}

.single-services-card {
  background-color: #ffffff;
  box-shadow: 0px 10px 40px rgba(67, 127, 236, 0.07);
  padding: 35px 45px;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 30px;
}
.single-services-card .image-icon {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  background: linear-gradient(0deg, #FFC2CC 0%, #FFF2F4 100%);
  text-align: center;
  border-radius: 50%;
  margin-bottom: 22px;
  transition: 0.5s;
}
.single-services-card .image-icon.bg-two {
  background: linear-gradient(0deg, #FFBEF9 0%, #FFF1FF 100%);
}
.single-services-card .image-icon.bg-three {
  background: linear-gradient(0deg, #FFE59A 0%, #FFFFD5 100%);
}
.single-services-card .image-icon.bg-four {
  background: linear-gradient(0deg, #FFBEF9 0%, #FFF1FF 100%);
}
.single-services-card .image-icon.bg-five {
  background: linear-gradient(0deg, #C3FFE8 0%, #F0FFF4 99.73%);
}
.single-services-card .image-icon.bg-six {
  background: linear-gradient(0deg, #ADDCFF 0%, #EAF6FF 50.28%, #EAF6FF 100%);
}
.single-services-card h3 {
  font-size: 23.8px;
  font-weight: bold;
  margin-bottom: 15.5px;
}
.single-services-card .read-btn {
  background: #FF4800;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-bottom: 1px solid #FF4800;
  font-size: 15px;
  font-weight: 600;
}
.single-services-card:hover {
  transform: translateY(-5px);
}
.single-services-card:hover .image-icon {
  animation: jackInTheBox 1s;
}

.ai-all-services-btn {
  text-align: center;
  margin-top: 10px;
}

.ai-services-shape {
  position: absolute;
  top: 15%;
  right: 15%;
  transform: translateY(-5%) translateX(-5%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

/*================================================
Computer Vision AI Area CSS
=================================================*/
.computer-vision-ai-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.computer-vision-ai-content h3 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.computer-vision-ai-content p {
  margin-bottom: 0;
}
.computer-vision-ai-content .vision-ai-inner-card {
  margin-top: 30px;
}
.computer-vision-ai-content .vision-ai-inner-card .content {
  position: relative;
  padding: 15px 0 15px 70px;
}
.computer-vision-ai-content .vision-ai-inner-card .content .image-icon {
  display: inline-block;
  height: 55px;
  width: 55px;
  line-height: 55px;
  background: #F3F9FF;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.computer-vision-ai-content .vision-ai-inner-card .content h4 {
  font-size: 22.5px;
  margin-bottom: 0;
  font-weight: bold;
}
.computer-vision-ai-content .vision-ai-inner-card p {
  margin-top: 15px;
  margin-bottom: 0;
}
.computer-vision-ai-content .computer-vision-ai-btn {
  margin-top: 30px;
}

/*================================================
AI Pricing Area CSS
=================================================*/
.ai-pricing-area {
  background-color: #F7FBFF;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.ai-pricing-area.with-linear-gradient-color {
  background: linear-gradient(270deg, #5151B7 0%, #A02B7E 100%);
}

.ai-pricing-card {
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(67, 127, 236, 0.07);
  padding: 45px 50px;
  border-radius: 5px;
  transition: 0.5s;
  position: relative;
  margin-bottom: 30px;
}
.ai-pricing-card .pricing-header {
  text-align: center;
}
.ai-pricing-card .pricing-header h3 {
  font-size: 23.5px;
  margin-bottom: 0;
  font-weight: bold;
}
.ai-pricing-card .pricing-header p {
  margin-top: 10px;
  margin-bottom: 0;
}
.ai-pricing-card .price {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #ff4800;
  font-family: "Comfortaa", sans-serif;
  margin-top: 22px;
  margin-bottom: 22px;
}
.ai-pricing-card .price span {
  font-size: 14px;
  color: #57647c;
  font-weight: 400;
}
.ai-pricing-card .pricing-features {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.ai-pricing-card .pricing-features li {
  margin-bottom: 14px;
  color: #57647c;
  font-size: 15px;
  position: relative;
  padding-left: 18px;
}
.ai-pricing-card .pricing-features li:last-child {
  margin-bottom: 0;
}
.ai-pricing-card .pricing-features li i {
  color: #03B5A9;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.ai-pricing-card .pricing-features li i::before {
  font-size: 12px;
}
.ai-pricing-card .pricing-features li i.fa-times {
  color: #E8346F;
}
.ai-pricing-card .pricing-btn {
  margin-top: 30px;
  text-align: center;
}
.ai-pricing-card .pricing-btn .btn-primary {
  background-color: transparent;
  border: 2px solid #ff4800;
  color: #57647c;
}
.ai-pricing-card .pricing-btn .btn-primary:hover, .ai-pricing-card .pricing-btn .btn-primary:focus {
  background-color: #ff4800 !important;
  color: #ffffff !important;
  box-shadow: unset !important;
}
.ai-pricing-card .tag {
  display: inline-block;
  background: linear-gradient(270deg, #FA887D 0%, #FE6088 100%);
  padding: 8px 25px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14.5px;
  font-weight: 600;
  position: absolute;
  right: 25px;
  top: -10px;
  animation: moveLeftBounce 5s linear infinite;
}
.ai-pricing-card:hover {
  transform: translateY(-5px);
}
.ai-pricing-card:hover .pricing-btn .btn-primary {
  background-color: #ff4800;
  border: 2px solid #ff4800;
  color: #ffffff;
}

.ai-pricing-content {
  padding-left: 20px;
}
.ai-pricing-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #ffffff;
}
.ai-pricing-content h3 span {
  text-decoration-line: underline;
  color: #ffffff;
}
.ai-pricing-content p {
  margin-bottom: 0;
  color: #ffffff;
}

.ai-pricing-shape-1 {
  position: absolute;
  top: 10%;
  left: 15%;
  transform: translateY(-10%) translateX(-15%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

.ai-pricing-shape-2 {
  position: absolute;
  top: 10%;
  right: 15%;
  transform: translateY(-10%) translateX(-15%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

/*================================================
AI Blog Area CSS
=================================================*/
.ai-blog-card {
  margin-bottom: 30px;
}
.ai-blog-card .entry-thumbnail {
  overflow: hidden;
}
.ai-blog-card .entry-thumbnail a img {
  transition: 0.5s;
}
.ai-blog-card .entry-post-content {
  margin-top: 25px;
}
.ai-blog-card .entry-post-content .tag {
  margin-bottom: 16.5px;
}
.ai-blog-card .entry-post-content .tag a {
  display: inline-block;
  background: rgba(255, 72, 0, 0.05);
  border-radius: 30px;
  color: #ff4800;
  padding: 10px 25px;
  font-weight: 600;
}
.ai-blog-card .entry-post-content h3 {
  font-size: 24.5px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
  position: relative;
  padding-left: 0;
  transition: 0.5s;
}
.ai-blog-card .entry-post-content h3 a {
  color: #333333;
  position: relative;
  display: inline;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.1% 0px;
  background-image: linear-gradient(270deg, #ff4800 0%, #ff4800 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  background-repeat: no-repeat;
}
.ai-blog-card .entry-post-content h3::before {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  height: 3.5px;
  width: 35px;
  background-color: #ff4800;
  z-index: -1;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.ai-blog-card .entry-post-content .entry-meta {
  padding: 0;
  margin-top: 18px;
  margin-bottom: 0;
}
.ai-blog-card .entry-post-content .entry-meta li {
  color: #57647c;
  margin-right: 15px;
  list-style-type: none;
  font-size: 14px;
  display: inline-block;
  position: relative;
  padding-left: 15px;
  font-weight: 500;
}
.ai-blog-card .entry-post-content .entry-meta li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  background-color: #ff4800;
  border-radius: 50%;
}
.ai-blog-card .entry-post-content .entry-meta li a {
  color: #57647c;
  display: inline;
}
.ai-blog-card .entry-post-content .entry-meta li a:hover {
  color: #ff4800;
  text-decoration-line: underline;
}
.ai-blog-card .entry-post-content .entry-meta li:last-child {
  margin-right: 0;
}
.ai-blog-card:hover .entry-thumbnail a img {
  transform: scale(1.2);
}
.ai-blog-card:hover .entry-post-content h3 {
  padding-left: 50px;
}
.ai-blog-card:hover .entry-post-content h3 a {
  color: #ff4800;
  background-size: 100% 2px;
}
.ai-blog-card:hover .entry-post-content h3::before {
  opacity: 1;
  visibility: visible;
}

/*================================================
AI Subscribe Area CSS
=================================================*/
.ai-subscribe-area {
  background: #F7FBFF;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ai-subscribe-content {
  text-align: center;
  max-width: 470px;
  margin: auto;
}
.ai-subscribe-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: bold;
}
.ai-subscribe-content .newsletter-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.ai-subscribe-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #ffffff;
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
  border: none;
  height: 55px;
  padding-left: 28px;
  border-radius: 70px;
  outline: 0;
  color: #57647c;
  font-weight: 500;
  font-size: 15px;
}
.ai-subscribe-content .newsletter-form .input-newsletter::placeholder {
  color: #57647c;
  transition: 0.5s;
}
.ai-subscribe-content .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}
.ai-subscribe-content .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ff4800;
  color: #ffffff;
  border: none;
  height: 55px;
  padding: 0 35px;
  border-radius: 70px;
  transition: 0.5s;
  line-height: 55px;
  font-family: "Comfortaa", sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.ai-subscribe-content .newsletter-form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn {
  margin-top: 20px;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn input {
  display: none;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx {
  margin: auto;
  user-select: none;
  cursor: pointer;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #437FEC;
  transition: all 0.2s ease;
  transition: 0.5s;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 1px;
  left: 1px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 12px;
  stroke-dashoffset: 12px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  transition: 0.5s;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #333333;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition: 0.5s;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx span:last-child {
  padding-left: 4px;
  color: #57647c;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .cbx:hover span:first-child {
  border-color: #ff4800;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .inp-cbx:checked + .cbx span:first-child {
  background: #ff4800;
  border-color: #ff4800;
  animation: wave 0.4s ease;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.ai-subscribe-content .newsletter-form .newsletter-checkbox-btn .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

.ai-subscribe-shape {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
}

/*================================================
ML Main Banner Area CSS
=================================================*/
.ml-main-banner-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #F5FCFE;
  padding-top: 70px;
  padding-bottom: 120px;
}
.ml-main-banner-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.ml-main-banner-area.with-linear-gradient-color {
  background: linear-gradient(0deg, #EB8A6F 0%, #E33879 100%);
  padding-top: 120px;
  padding-bottom: 230px;
}
.ml-main-banner-area.with-linear-gradient-color .container-fluid {
  padding-right: 0;
}

.ml-main-banner-content .sub-title {
  background-color: #ffffff;
  box-shadow: -2px 6px 15px rgba(67, 127, 236, 0.07);
  padding: 12px 15px;
  border-radius: 5px;
  color: #57647c;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 22px;
}
.ml-main-banner-content .sub-title b {
  background: #738BAE;
  padding: 2px 12px;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 500;
  margin-left: 5px;
}
.ml-main-banner-content h1 {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 18.5px;
}
.ml-main-banner-content h1 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.ml-main-banner-content p {
  margin-bottom: 0;
  max-width: 550px;
  font-size: 15.8px;
}
.ml-main-banner-content .banner-form {
  max-width: 550px;
  position: relative;
  margin-top: 30px;
}
.ml-main-banner-content .banner-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #ffffff;
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
  border: none;
  height: 55px;
  padding-left: 28px;
  border-radius: 70px;
  outline: 0;
  color: #57647c;
  font-weight: 500;
  font-size: 15px;
}
.ml-main-banner-content .banner-form .input-newsletter::placeholder {
  color: #57647c;
  transition: 0.5s;
}
.ml-main-banner-content .banner-form .input-newsletter:focus::placeholder {
  color: transparent;
}
.ml-main-banner-content .banner-form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ff4800;
  color: #ffffff;
  border: none;
  height: 55px;
  padding: 0 35px;
  border-radius: 70px;
  transition: 0.5s;
  line-height: 55px;
  font-family: "Comfortaa", sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.ml-main-banner-content .banner-form button:hover {
  background-color: #4ac728;
  color: #ffffff;
}
.ml-main-banner-content .banner-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.ml-main-banner-content .banner-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.ml-main-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.ml-main-banner-content .banner-btn li .video-btn {
  margin-left: 5px;
  font-size: 14.5px;
  font-weight: 600;
  color: #ffffff;
}
.ml-main-banner-content .banner-btn li .video-btn i {
  background: linear-gradient(320.56deg, #074ECE 0%, #4D8CFF 100%);
  box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.12);
  height: 40px;
  width: 40px;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
}
.ml-main-banner-content .banner-btn li .btn-primary {
  background: #561A8A;
  box-shadow: -3px 8px 20px rgba(255, 72, 0, 0.13);
}
.ml-main-banner-content.with-white-color-text h1 {
  color: #ffffff;
}
.ml-main-banner-content.with-white-color-text h1 span {
  color: #ffffff;
}
.ml-main-banner-content.with-white-color-text p {
  color: #ffffff;
}

.ml-main-banner-image {
  position: relative;
  z-index: 1;
}
.ml-main-banner-image .circle-shape {
  position: absolute;
  content: "";
  width: 530px;
  height: 530px;
  left: 80px;
  top: 25px;
  background: linear-gradient(109.28deg, #FE6D5B 11.53%, #FE9C42 89.63%);
  border-radius: 50%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.ml-main-banner-image .wrap-shape {
  position: absolute;
  bottom: 30px;
  left: 100px;
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}
.ml-main-banner-image .wrap-text {
  background-color: #ffffff;
  box-shadow: -3px 4px 30px rgba(67, 127, 236, 0.09);
  border-radius: 5px 30.5px 5px 5px;
  display: inline-block;
  padding: 15px 15px 15px 35px;
  position: absolute;
  top: 15%;
  right: 12%;
  transform: translateY(-15%) translateX(-12%);
}
.ml-main-banner-image .wrap-text i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #03B5A9;
  box-shadow: 0px 4px 10px rgba(3, 181, 169, 0.12);
  text-align: center;
  border-radius: 50px;
  color: #ffffff;
  position: absolute;
  left: -8px;
  top: -2px;
}
.ml-main-banner-image .wrap-text span {
  color: #57647c;
  font-weight: 500;
}

.ai-main-banner-shape1 {
  position: absolute;
  top: 8%;
  left: 5%;
  transform: translateY(-8%) translateX(-5%);
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}

.ai-main-banner-shape2 {
  position: absolute;
  bottom: 10%;
  left: 40%;
  transform: translateY(-10%) translateX(-40%);
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}

.ai-main-banner-shape3 {
  position: absolute;
  top: 8%;
  right: 10%;
  transform: translateY(-8%) translateX(-10%);
  z-index: -1;
  animation: animationFramesTwo 30s infinite linear;
}

.ai-main-banner-shape4 {
  position: absolute;
  top: 5%;
  left: 2%;
  transform: translateY(-5%) translateX(-2%);
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}

.ml-main-banner-wrap-image {
  position: relative;
  z-index: 1;
  padding-left: 10px;
}
.ml-main-banner-wrap-image .circle-shape {
  width: 890px;
  height: 550px;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -160px;
}

/*================================================
Business Solution Area CSS
=================================================*/
.business-solution-area {
  background-color: #F7FBFF;
}

.business-solution-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.business-solution-content h3 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.business-solution-content p {
  margin-bottom: 0;
}
.business-solution-content .solution-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
  max-width: 450px;
}
.business-solution-content .solution-list li {
  list-style-type: none;
  background-color: #ffffff;
  box-shadow: -3px 4px 30px rgba(67, 127, 236, 0.08);
  padding: 14px 15px 15px 42px;
  border-radius: 5px;
  margin-bottom: 18px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #57647c;
}
.business-solution-content .solution-list li:last-child {
  margin-bottom: 0;
}
.business-solution-content .solution-list li i {
  font-size: 14px;
  color: #03B5A9;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.business-solution-content .solution-btn {
  margin-top: 30px;
}
.business-solution-content.with-some-gap {
  padding-left: 30px;
}

.business-solution-image {
  position: relative;
  z-index: 1;
  text-align: center;
}
.business-solution-image .circle-shape {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}
.business-solution-image .wrap-text {
  background-color: #ffffff;
  box-shadow: -3px 4px 30px rgba(67, 127, 236, 0.09);
  border-radius: 5px 30.5px 5px 5px;
  display: inline-block;
  padding: 15px 15px 15px 35px;
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translateY(-10%) translateX(-20%);
}
.business-solution-image .wrap-text i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #03B5A9;
  box-shadow: 0px 4px 10px rgba(3, 181, 169, 0.12);
  text-align: center;
  border-radius: 50px;
  color: #ffffff;
  position: absolute;
  left: -8px;
  top: -2px;
}
.business-solution-image .wrap-text span {
  color: #57647c;
  font-weight: 500;
}

/*================================================
Unique Testimonials Area CSS
=================================================*/
.unique-testimonials-box {
  background: #F7FBFF;
  padding: 70px 100px 0;
  border-radius: 5px;
  text-align: center;
  max-width: 1050px;
  margin: auto;
  position: relative;
  z-index: 1;
  margin-top: 15px;
}
.unique-testimonials-box .sub-title {
  display: inline-block;
  background: rgba(255, 72, 0, 0.05);
  border-radius: 30px;
  color: #ff4800;
  padding: 10px 25px;
  font-weight: 600;
  margin-bottom: 22px;
}
.unique-testimonials-box p {
  color: #333333;
  margin-bottom: 0;
  font-size: 22.8px;
  position: relative;
  padding-bottom: 22px;
}
.unique-testimonials-box p::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 3.5px;
  border-radius: 20px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  background: #437FEC;
}
.unique-testimonials-box .client-info {
  margin-top: 25px;
}
.unique-testimonials-box .client-info h3 {
  margin-bottom: 12px;
  font-size: 23.8px;
  font-weight: 700;
}
.unique-testimonials-box .client-info span {
  color: #57647c;
}
.unique-testimonials-box .client-info .image {
  margin-top: 20px;
}
.unique-testimonials-box .client-info .image img {
  display: inline-block;
  width: auto;
  max-width: 100px;
  border: 5px solid #ffffff;
}
.unique-testimonials-box::before {
  color: #ff4800;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  font-style: normal;
  font-size: 50px;
  font-family: Flaticon;
  content: "\f113";
}
.unique-testimonials-box::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 45px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.unique-testimonials-box .testimonials-shape-1 {
  position: absolute;
  left: -50px;
  top: -10px;
}
.unique-testimonials-box .testimonials-shape-1 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.unique-testimonials-box .testimonials-shape-2 {
  position: absolute;
  right: -35px;
  bottom: 10px;
}
.unique-testimonials-box .testimonials-shape-2 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.unique-testimonials-slides .owl-dots {
  margin-top: 30px !important;
}
.unique-testimonials-slides .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 3px;
  background: transparent;
  transition: 0.5s;
  border: 2px solid #8d95a7;
  border-radius: 50%;
  position: relative;
}
.unique-testimonials-slides .owl-dots .owl-dot span::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ff4800;
  margin: 2px;
  content: "";
  transition: 0.5s;
  border-radius: 50%;
  transform: scale(0);
}
.unique-testimonials-slides .owl-dots .owl-dot:hover span, .unique-testimonials-slides .owl-dots .owl-dot.active span {
  border-color: #ff4800;
  background-color: transparent;
}
.unique-testimonials-slides .owl-dots .owl-dot:hover span::before, .unique-testimonials-slides .owl-dots .owl-dot.active span::before {
  transform: scale(1);
}

/*================================================
Creativity Area CSS
=================================================*/
.creativity-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.creativity-content h3 span {
  text-decoration-line: underline;
  color: #437FEC;
}
.creativity-content p {
  margin-bottom: 0;
}
.creativity-content .skill-bar {
  margin-top: 25px;
}
.creativity-content .skill-bar .progress-title-holder {
  position: relative;
  margin-bottom: 10px;
}
.creativity-content .skill-bar .progress-title {
  font-size: 16.5px;
  color: #333333;
  font-weight: bold;
}
.creativity-content .skill-bar .progress-number-mark {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 500;
  color: #57647c;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: -2px 4px 30px rgba(67, 127, 236, 0.05);
  border: 1px solid #f9f9f9;
  transition: 0.5s;
}
.creativity-content .skill-bar .progress-number-mark:hover {
  background-color: #ff4800;
  color: #ffffff;
  border: 1px solid #ff4800;
}
.creativity-content .skill-bar .progress-content-outter {
  background-color: #E3E9FF;
  border-radius: 50px;
}
.creativity-content .skill-bar .progress-content {
  height: 5px;
  background: linear-gradient(90deg, #FFA68D 0%, #FD3A84 100%);
  border-radius: 50px;
  width: 0%;
}
.creativity-content .creativity-btn {
  margin-top: 30px;
}

.creativity-card {
  background-color: #ffffff;
  box-shadow: 0px 10px 40px rgba(67, 127, 236, 0.07);
  border-radius: 5px;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
}
.creativity-card .icon-image {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  box-shadow: 0px 10px 40px rgba(67, 127, 236, 0.07);
  background-color: #ffffff;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 22px;
  transition: 0.5s;
}
.creativity-card h3 {
  font-size: 22.5px;
  font-weight: bold;
  margin-bottom: 0;
}
.creativity-card:hover .icon-image {
  animation: jackInTheBox 1s;
}

.creativity-card-content {
  position: relative;
  z-index: 1;
}
.creativity-card-content .col-lg-6:nth-child(2) {
  margin-top: 30px;
}
.creativity-card-content .col-lg-6:nth-child(4) {
  margin-top: 30px;
}
.creativity-card-content .creativity-circle-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: -1;
}

/*================================================
AI Team Area CSS
=================================================*/
.ai-team-area {
  background: #F5FCFF;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ai-team-card {
  text-align: center;
  max-width: 230px;
  margin: 0 auto 30px;
}
.ai-team-card .team-image {
  position: relative;
}
.ai-team-card .team-image img {
  border: 5px solid #ffffff;
}
.ai-team-card .team-image .social {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  text-decoration: none;
  padding: 10px 12px;
  background: linear-gradient(270deg, #FA887D 0%, #FE6088 100%);
  border-radius: 35px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.ai-team-card .team-image .social a {
  display: block;
  color: #ffffff;
  font-size: 16.8px;
  margin: 8px 0;
  transition: 0.5s;
}
.ai-team-card .team-image .social a:hover {
  color: #333333;
}
.ai-team-card .team-content {
  margin-top: 30px;
  padding-top: 25px;
  position: relative;
}
.ai-team-card .team-content::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  background: #437FEC;
  border-radius: 15px;
  height: 3.5px;
  width: 35px;
}
.ai-team-card .team-content h3 {
  font-size: 22.5px;
  margin-bottom: 0;
  font-weight: bold;
}
.ai-team-card .team-content span {
  color: #57647c;
  font-weight: 500;
  display: inline-block;
  margin-top: 10px;
}
.ai-team-card:hover .team-image .social {
  opacity: 1;
  visibility: visible;
}

.ai-all-team-btn {
  margin-top: 10px;
  text-align: center;
}

.ai-team-shape-1 {
  position: absolute;
  top: 10%;
  left: 5%;
  transform: translateY(-10%) translateX(-5%);
  z-index: -1;
}
.ai-team-shape-1 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ai-team-shape-2 {
  position: absolute;
  bottom: 10%;
  right: 5%;
  transform: translateY(-10%) translateX(-5%);
  z-index: -1;
}
.ai-team-shape-2 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*================================================
Footer Wrap Area CSS
=================================================*/
.footer-wrap-area {
  background-color: #1A1F56;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-wrap-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-align: center;
  opacity: 55%;
}

.footer-wrap-shape-2 {
  position: absolute;
  top: 10%;
  right: 10%;
  transform: translateY(-10%) translateX(-10%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}

.footer-wrap-shape-3 {
  position: absolute;
  bottom: 15%;
  left: 10%;
  transform: translateY(-15%) translateX(-10%);
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}